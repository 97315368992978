<template>
    <div>
        <!--edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Planos</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="PlanosGarantePay.cpgId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input v-model="PlanosGarantePay.cpgNome" :rules="nameRules" :counter="100"
                                                type="text" class="form-control" placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Ativo</label>
                                            <select v-model="PlanosGarantePay.cpgAtivo" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Valor</label>
                                            <input v-model="PlanosGarantePay.cpgValor" v-decimal
                                                class="form-control" placeholder="Valor" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Tipo Cobrança</label>
                                            <select v-model="PlanosGarantePay.cpgTipoCobranca" class="form-select form-control">
                                                <option :value=null>Selecione</option>
                                                <option value="Pré">Pré</option>
                                                <option value="Pós">Pós</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Remuneração por Negócio</label>
                                            <input v-model="PlanosGarantePay.cpgRemuneracaoNegocio" v-decimal
                                                class="form-control" placeholder="Remuneração" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Valor de Adesão R$</label>
                                            <input v-model="PlanosGarantePay.cpgValorAdesao" v-decimal
                                                class="form-control" placeholder="Valor de Adesão" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Usuários</label>
                                            <input v-model="PlanosGarantePay.cpgQtdeUsuarios" type="number"
                                                class="form-control" placeholder="Qtde Usuários" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Cód. Produto Delta</label>
                                            <input v-model="PlanosGarantePay.cpgCodProdutoDelta" type="number"
                                                class="form-control" placeholder="Cód. Produto Delta" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Serasa Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgSerasaQtdeMensal" type="number"
                                                class="form-control" placeholder="Qtde Serasa Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Custo Extra Serasa</label>
                                            <input v-model="PlanosGarantePay.cpgSerasaCustoExtra" v-decimal
                                                class="form-control" placeholder="Custo Extra Serasa" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Serasa Grátis Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgSerasaQtdeGratisMensal" type="number"
                                                class="form-control" placeholder="Qtde Serasa Grátis Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Assinaturas Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgAssQtdeMensal" type="number"
                                                class="form-control" placeholder="Qtde Assinaturas Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Custo Extra Assinaturas</label>
                                            <input v-model="PlanosGarantePay.cpgAssCustoExtra" v-decimal
                                                class="form-control" placeholder="Custo Extra Assinaturas" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Assinaturas Grátis Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgAssQtdeGratisMensal" type="number"
                                                class="form-control" placeholder="Qtde Assinaturas Grátis Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Processos Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgProcessosQtdeMensal" type="number"
                                                class="form-control" placeholder="Qtde Processos Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Custo Extra Processos</label>
                                            <input v-model="PlanosGarantePay.cpgProcessosCustoExtra" v-decimal
                                                class="form-control" placeholder="Custo Extra Processos" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Qtde Processos Grátis Mensal</label>
                                            <input v-model="PlanosGarantePay.cpgProcessosQtdeGratisMensal" type="number"
                                                class="form-control" placeholder="Qtde Processos Grátis Mensal" />
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Texto</label>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="PlanosGarantePay.cpgTexto" :config="$ckconfig"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="PlanosGarantePay.cpgId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de planos</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="PlanosGarantePays" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-cpgAtivo="item">
                                        <span>{{ item.cpgAtivo ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-cpgvalor="item">
                                        <span>{{ item.cpgValor !== '' ? parseFloat(item.cpgValor).toFixed(2).replace('.', ',') : '0,00' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="cpg">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="cpg">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    name: 'CadastroPlanosGarantePayView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        editor: DecoupledEditor,
        PlanosGarantePay: {
            cpgId: 0,
            cpgNome: '',
            cpgTexto: '',
            cpgAtivo: '',
            cpgValor:'',
            cpgTipoCobranca:'',
            cpgRemuneracaoNegocio: '',
            cpgQtdeUsuarios:'',
            cpgValorAdesao:'',
            cpgCodProdutoDelta:'',
            cpgSerasaQtdeMensal: '',
            cpgSerasaCustoExtra:'',
            cpgSerasaQtdeGratisMensal:'',
            cpgAssQtdeMensal: '',
            cpgAssCustoExtra:'',
            cpgAssQtdeGratisMensal:'',
            cpgProcessosQtdeMensal: '',
            cpgProcessosCustoExtra:'',
            cpgProcessosQtdeGratisMensal:'',
        },
        PlanosGarantePays: [],
        selectedVisivel: null,
        headers: [
            { text: "Id", value: "cpgId", sortable: true, },
            { text: "Nome", value: "cpgNome", sortable: true, },
            { text: "Valor R$", value: "cpgValor", sortable: true, },
            { text: "Ativo", value: "cpgAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
          //edit
          EditNew(){
            this.EditView = true;
            this.PlanosGarantePay.cpgId = 0;
            this.PlanosGarantePay.cpgNome= '';
            this.PlanosGarantePay.cpgTexto= '';
            this.PlanosGarantePay.cpgAtivo= '';
            this.PlanosGarantePay.cpgValor= '';
            this.PlanosGarantePay.cpgTipoCobranca= '';
            this.PlanosGarantePay.cpgRemuneracaoNegocio= '';
            this.PlanosGarantePay.cpgQtdeUsuarios= '';
            this.PlanosGarantePay.cpgValorAdesao= '';
            this.PlanosGarantePay.cpgCodProdutoDelta= '';
            this.PlanosGarantePay.cpgSerasaQtdeMensal= '';
            this.PlanosGarantePay.cpgSerasaCustoExtra= '';
            this.PlanosGarantePay.cpgSerasaQtdeGratisMensal= '';
            this.PlanosGarantePay.cpgAssQtdeMensal= '';
            this.PlanosGarantePay.cpgAssCustoExtra= '';
            this.PlanosGarantePay.cpgAssQtdeGratisMensal= '';
            this.PlanosGarantePay.cpgProcessosQtdeMensal= '';
            this.PlanosGarantePay.cpgProcessosCustoExtra= '';
            this.PlanosGarantePay.cpgProcessosQtdeGratisMensal= '';
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.cpgId != ''){
            axios.get(`/Process/CadPlanosGarantePay/GetById/` + item.cpgId)
                .then(response => {
                    this.PlanosGarantePay = response.data;
                    this.PlanosGarantePay.cpgTexto= '';
                    this.PlanosGarantePay.cpgValor = String(this.PlanosGarantePay.cpgValor).replace('.', ',');
                    this.PlanosGarantePay.cpgRemuneracaoNegocio= String(this.PlanosGarantePay.cpgRemuneracaoNegocio).replace('.', ',');
                    this.PlanosGarantePay.cpgValorAdesao= String(this.PlanosGarantePay.cpgValorAdesao).replace('.', ',');
                    this.PlanosGarantePay.cpgSerasaCustoExtra= String(this.PlanosGarantePay.cpgSerasaCustoExtra).replace('.', ',');
                    this.PlanosGarantePay.cpgAssCustoExtra= String(this.PlanosGarantePay.cpgAssCustoExtra).replace('.', ',');
                    this.PlanosGarantePay.cpgProcessosCustoExtra= String(this.PlanosGarantePay.cpgProcessosCustoExtra).replace('.', ',');
                });
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        Add() {
            if (this.PlanosGarantePay.cpgNome == '' || this.PlanosGarantePay.cpgNome == null) {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgTipoCobranca === '' || this.PlanosGarantePay.cpgTipoCobranca === null) {
                this.$mensagemAviso("Tipo Cobrança Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgValor === '' || this.PlanosGarantePay.cpgValor === null) {
                this.$mensagemAviso("Valor Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgQtdeUsuarios === '' || this.PlanosGarantePay.cpgQtdeUsuarios === null) {
                this.$mensagemAviso("Qtde de Usuários Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgCodProdutoDelta === '' || this.PlanosGarantePay.cpgCodProdutoDelta === null) {
                this.$mensagemAviso("Código produto Delta Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgSerasaQtdeMensal === '' || this.PlanosGarantePay.cpgSerasaQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Serasa Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAssQtdeMensal === '' || this.PlanosGarantePay.cpgAssQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Assinaturas Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgProcessosQtdeMensal === '' || this.PlanosGarantePay.cpgProcessosQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Processos Obrigatório.")
            }
            else {
                this.PlanosGarantePay.cpgValor = parseFloat(String(this.PlanosGarantePay.cpgValor).replace(',', '.'));

                if (this.PlanosGarantePay.cpgRemuneracaoNegocio != '' && this.PlanosGarantePay.cpgRemuneracaoNegocio != null){
                    this.PlanosGarantePay.cpgRemuneracaoNegocio= parseFloat(String(this.PlanosGarantePay.cpgRemuneracaoNegocio).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgRemuneracaoNegocio= 0;
                }

                if (this.PlanosGarantePay.cpgValorAdesao != '' && this.PlanosGarantePay.cpgValorAdesao != null){
                    this.PlanosGarantePay.cpgValorAdesao= parseFloat(String(this.PlanosGarantePay.cpgValorAdesao).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgValorAdesao= 0;
                }

                if (this.PlanosGarantePay.cpgSerasaCustoExtra != '' && this.PlanosGarantePay.cpgSerasaCustoExtra != null){
                    this.PlanosGarantePay.cpgSerasaCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgSerasaCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgSerasaCustoExtra= 0;
                }

                if (this.PlanosGarantePay.cpgAssCustoExtra != '' && this.PlanosGarantePay.cpgAssCustoExtra != null){
                    this.PlanosGarantePay.cpgAssCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgAssCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgAssCustoExtra= 0;
                }

                if (this.PlanosGarantePay.cpgProcessosCustoExtra != '' && this.PlanosGarantePay.cpgProcessosCustoExtra != null){
                    this.PlanosGarantePay.cpgProcessosCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgProcessosCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgProcessosCustoExtra= 0;
                }
                if (this.PlanosGarantePay.cpgSerasaQtdeGratisMensal === '' || this.PlanosGarantePay.cpgSerasaQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgSerasaQtdeGratisMensal= 0;
                }

                if (this.PlanosGarantePay.cpgAssQtdeGratisMensal === '' || this.PlanosGarantePay.cpgAssQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgAssQtdeGratisMensal= 0;
                }

                if (this.PlanosGarantePay.cpgProcessosQtdeGratisMensal === '' || this.PlanosGarantePay.cpgProcessosQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgProcessosQtdeGratisMensal= 0;
                }
               
                axios.post(`/Process/CadPlanosGarantePay/Add`, this.PlanosGarantePay)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.PlanosGarantePay.cpgNome == '' || this.PlanosGarantePay.cpgNome == null) {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgTipoCobranca === '' || this.PlanosGarantePay.cpgTipoCobranca === null) {
                this.$mensagemAviso("Tipo Cobrança Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgValor === '' || this.PlanosGarantePay.cpgValor === null) {
                this.$mensagemAviso("Valor Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgQtdeUsuarios === '' || this.PlanosGarantePay.cpgQtdeUsuarios === null) {
                this.$mensagemAviso("Qtde de Usuários Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgCodProdutoDelta === '' || this.PlanosGarantePay.cpgCodProdutoDelta === null) {
                this.$mensagemAviso("Código produto Delta Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgSerasaQtdeMensal === '' || this.PlanosGarantePay.cpgSerasaQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Serasa Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAssQtdeMensal === '' || this.PlanosGarantePay.cpgAssQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Assinaturas Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgProcessosQtdeMensal === '' || this.PlanosGarantePay.cpgProcessosQtdeMensal === null) {
                this.$mensagemAviso("Qtde Consultas Processos Obrigatório.")
            }
            else {
                this.PlanosGarantePay.cpgValor = parseFloat(String(this.PlanosGarantePay.cpgValor).replace(',', '.'));

                if (this.PlanosGarantePay.cpgRemuneracaoNegocio != '' && this.PlanosGarantePay.cpgRemuneracaoNegocio != null){
                    this.PlanosGarantePay.cpgRemuneracaoNegocio= parseFloat(String(this.PlanosGarantePay.cpgRemuneracaoNegocio).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgRemuneracaoNegocio= 0;
                }

                if (this.PlanosGarantePay.cpgValorAdesao != '' && this.PlanosGarantePay.cpgValorAdesao != null){
                    this.PlanosGarantePay.cpgValorAdesao= parseFloat(String(this.PlanosGarantePay.cpgValorAdesao).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgValorAdesao= 0;
                }

                if (this.PlanosGarantePay.cpgSerasaCustoExtra != '' && this.PlanosGarantePay.cpgSerasaCustoExtra != null){
                    this.PlanosGarantePay.cpgSerasaCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgSerasaCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgSerasaCustoExtra= 0;
                }

                if (this.PlanosGarantePay.cpgAssCustoExtra != '' && this.PlanosGarantePay.cpgAssCustoExtra != null){
                    this.PlanosGarantePay.cpgAssCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgAssCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgAssCustoExtra= 0;
                }

                if (this.PlanosGarantePay.cpgProcessosCustoExtra != '' && this.PlanosGarantePay.cpgProcessosCustoExtra != null){
                    this.PlanosGarantePay.cpgProcessosCustoExtra= parseFloat(String(this.PlanosGarantePay.cpgProcessosCustoExtra).replace(',', '.'));
                }
                else{
                    this.PlanosGarantePay.cpgProcessosCustoExtra= 0;
                }

                if (this.PlanosGarantePay.cpgSerasaQtdeGratisMensal === '' || this.PlanosGarantePay.cpgSerasaQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgSerasaQtdeGratisMensal= 0;
                }

                if (this.PlanosGarantePay.cpgAssQtdeGratisMensal === '' || this.PlanosGarantePay.cpgAssQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgAssQtdeGratisMensal= 0;
                }

                if (this.PlanosGarantePay.cpgProcessosQtdeGratisMensal === '' || this.PlanosGarantePay.cpgProcessosQtdeGratisMensal === null){
                    this.PlanosGarantePay.cpgProcessosQtdeGratisMensal= 0;
                }

                axios.put(`/Process/CadPlanosGarantePay/Update`, this.PlanosGarantePay)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cpgId;
                axios.delete(`/Process/CadPlanosGarantePay/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        Get() {
            this.EditView = false;
            axios.get(`/Process/CadPlanosGarantePay/GetAll`)
                .then(response => {
                    this.PlanosGarantePays = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
