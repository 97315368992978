<template>
    <div>
        <div class="container" v-if="ListGarantidorasGeral!=''">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Contratação Garantia Locatícia</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                                <v-tab value="dados" class="tabinfo">Detalhe Contratação</v-tab>
                                <v-tab value="documentos" class="tabinfo">Documentos</v-tab>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container v-if="PedidosCli != '' && PedidosEndereco != ''">
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <v-card style="padding: 15px;" color="#f7f7f7">
                                                                    <div>
                                                                        <h5 class="subtitulo" style="padding-bottom: 15px;">Endereço Contratação
                                                                        </h5>
                                                                        <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                                            <strong>Endereço:</strong> {{
                                                                                            PedidosEndereco[0].pdcEndereco
                                                                                        }}, {{ PedidosEndereco[0].pdcNumero }} - {{
                                                                                            PedidosEndereco[0].pdcComplemento }}
                                                                        </p>
                                                                        <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                                            <strong>Cidade/UF:</strong> {{
                                                                                                    PedidosEndereco[0].pdcCidade }} /
                                                                            {{ PedidosEndereco[0].pdcEstado }}
                                                                        </p>
                                                                        <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                                            <strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro
                                                                            }}
                                                                        </p>
                                                                        <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                                            <strong>CEP:</strong> {{ PedidosEndereco[0].pdcCep }}
                                                                        </p>
                                                                        <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                                            <strong>Tipo Imóvel:</strong> {{ PedidosEndereco[0].pdcTipo
                                                                            }} {{ PedidosEndereco[0].pdcResidencialTipo }}
                                                                        </p>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <v-card style="padding: 15px;min-height: 215px;" color="#f7f7f7">
                                                                    <div>
                                                                        <h5 class="subtitulo" style="padding-bottom: 15px;">
                                                                            Informações Contratação</h5>
                                                                        <h6 class="mb-0"><strong>Contratação: {{ PedidosCli.pedId }}</strong></h6>
                                                                        <div class="pedido_dados"><strong>Garantidora:</strong> {{ DadosGarantidora.garNomeEmpresa }}</div>
                                                                        <div class="pedido_dados"><strong>Imobiliária:</strong> {{ Imobiliaria.imoNomeEmpresa }} - {{ Imobiliaria.imoEmail }} <v-icon @click="openDialogImobiliaria(Imobiliaria.imoId)">mdi-folder-open</v-icon> </div>
                                                                        <div class="pedido_dados"><strong>Cliente:</strong> {{ Cliente.cliNomeEmpresa }} - {{ Cliente.cliEmail }}  <v-icon @click="openDialogCliente(Cliente.cliId)">mdi-folder-open</v-icon></div>
                                                                        <div class="pedido_dados"><strong>Data :</strong> {{ formattedDate(PedidosCli.pedDataPedido) }}</div>
                                                                        <div class="pedido_dados" v-if="ListDocumentosAss!=null">
                                                                            <div v-if="ListDocumentosAss[0].pftUrlDocAssinado!= '' && ListDocumentosAss[0].pftUrlDocAssinado!= null">
                                                                                <strong>Contrato:</strong> <a :href="$imgURL + ListDocumentosAss[0].pftUrlDocAssinado" target="_blank">Clique para visualizar</a> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <v-card style="padding: 25px;" color="#f9f9f9">
                                                                    <div>
                                                                        <h5 class="subtitulo" style="padding-bottom: 15px;">Produto</h5>
                                                                        <div v-for="produto in ListaPedidoItem" :key="produto.pdiId"
                                                                            class="descricao-pedido border-top py-3">
                                                                            <div class="row">
                                                                                <div v-if="ListaProdutosImg!=''"
                                                                                    class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                                    <div class="descricao-img">
                                                                                        <img  v-if="ListaProdutosImg.filter(x=> x.proId === produto.proId) != ''" :src="$imgURL + ListaProdutosImg.filter(x=> x.proId == produto.proId)[0].pimUrlImagem"  class="img-fluid border border-rounded mb-3 mb-md-0"/>
                                                                                        <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                            src="/imagens/indisponivel.jpg"
                                                                                            alt="">
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-6 col-md-5 d-flex align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <h6 class="subtitulo" >{{produto.pdiNomeProduto}}</h6>
                                                                                        <p class="texto-mensagem" style="margin: 0 0 0px"> Código: {{produto.proId }}</p>
                                                                                        <p class="texto-mensagem" style="margin: 0 0 0px; display: inline-flex;"> 
                                                                                            <v-text-field v-model="produto.pdiDataVigenciaIni" type="date" disabled label="Data Vigência Inicial:" variant="underlined" style="padding-right: 15px;"></v-text-field>
                                                                                            <v-text-field v-model="produto.pdiDataVigenciaFim" type="date" disabled label="Data Vigência Final:" variant="underlined"></v-text-field> 
                                                                                        </p>
                                                                                        <p class="texto-mensagem" style="margin: 0 0 0px"> Setup: R$ {{ formatarValor(produto.pdiCampoExtra1) }}</p>
                                                                                        <p class="texto-mensagem" style="margin: 0 0 0px"> Taxa: {{produto.pdiCampoExtra2 }}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6 col-md-3 d-flex align-items-center">
                                                                                    <ul>
                                                                                        <li v-for="(sub,index) in ListaPedidoItemAdicional" :key="index" style="font-size: 12px;"> 
                                                                                        {{ sub.piaNomeProduto }} - R$ {{ formatarValor(sub.piaValorTotalProduto) }}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div
                                                                                    class="col-6 col-md-2 d-flex align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <h6 class="subtitulo"
                                                                                            style="font-size: 15px;">
                                                                                            R$ {{
                                                                                            parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.',
                                                                                            ',')
                                                                                            }}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <v-card style="padding: 25px;" color="#f7f7f7">
                                                                    <div>
                                                                        <div
                                                                            class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="subtitulo">Total</h5>
                                                                            <h6 class="subtitulo">R$ {{
                                                                                parseFloat(TotalPedido).toFixed(2).toString().replace('.',
                                                                                ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="5" style="text-align:center;" >
                                                        <v-card style="padding: 25px;" color="#f7f7f7">
                                                            <div v-if="User.tpeid != 3">
                                                                <h5 class="subtitulo" style="padding-bottom: 15px;">
                                                                    Nível de Aprovação
                                                                </h5>
                                                                <v-row style="padding-top: 40px;justify-content: center;">
                                                                    <VueSpeedometer
                                                                        :value="score"
                                                                        :minValue="0"
                                                                        :maxValue="1000"
                                                                        segments="10"
                                                                        needleColor="steelblue"
                                                                        style="height: 200px"
                                                                        />
                                                                </v-row>
                                                                <button type="button" class="btn btn-info" @click="dialogSerasa=!dialogSerasa;" style="color:#ffffff;margin:5px;padding:10px;">
                                                                    Análise Crédito
                                                                </button>
                                                                <button type="button" class="btn btn-info" @click="dialogJusBrasil=!dialogJusBrasil;" style="color:#ffffff;margin:5px;padding:10px;">
                                                                    Análise Jurídica
                                                                </button>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" md="7">
                                                        <v-card style="padding: 25px;" color="#f7f7f7">
                                                            <div>
                                                                <h5 class="subtitulo" style="padding-bottom: 15px;">Situação Atual: {{PedidosCli.pedSituacaoPedido}}
                                                                </h5>
                                                                <v-row v-if="User.tpeid != 3" style="padding-top: 20px">
                                                                    <label>Observações Internas</label>
                                                                    <textarea disabled cols="12" rows="5"  class="form-control" v-model="PedidosCli.pedObsLoja" label="Observações"></textarea>
                                                                </v-row>
                                                                <v-row style="padding-top: 20px">
                                                                    <label>Observações</label>
                                                                    <textarea disabled cols="12" rows="5"  class="form-control" v-model="PedidosCli.pedObsCliente" label="Observações"></textarea>
                                                                </v-row>
                                                                <v-row style="justify-content: center;padding-top: 15px;">
                                                                    <button type="button" class="btn btn-info" style="color:#ffffff;margin: 5px;padding:10px;"
                                                                        @click="AcionarSinistro()">
                                                                         Acionar Sinistro
                                                                    </button>
                                                                </v-row>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <v-window-item value="documentos" >
                                        <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;" v-if="User.tpeid != 3 && (PedidosCli.pspId == 1 || PedidosCli.pspId == 2 || PedidosCli.pspId == 8)">Selecione os documento necessários</div>
                                        <v-form>
                                            <v-form>
                                                <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="3">
                                                        <label v-if="User.tpeid === 3">Selecione o Tipo</label>
                                                        <select v-if="User.tpeid === 3"  v-model="DocSelected" class="form-select form-control"> <!--v-if="User.tpeid === 1 || User.tpeid === 2 || User.tpeid === 3"-->
                                                            <option :value=null>Selecione</option>
                                                            <option v-for="optionTipo in uniqueDocumentos" :key="optionTipo.pmsId"
                                                                :value="optionTipo">{{ optionTipo.pmsNome }}</option>
                                                        </select>
                                                        <label v-if="User.tpeid != 3">Nome Documento</label>
                                                        <input v-if="User.tpeid != 3 && (PedidosCli.pspId == 1 || PedidosCli.pspId == 2 || PedidosCli.pspId == 8)" v-model="nomeDocumento" type="text" class="form-control" placeholder="Nome Documento" />
                                                    </v-col>
                                                    <v-col cols="12" md="5" v-if="User.tpeid === 3">
                                                        <label for="exampleFormControlFile1">*JPG, JPEG, PNG ou PDF</label>
                                                        <v-file-input v-model="selectedFile" label="Selecione o arquivo" variant="underlined" prepend-icon="mdi-file" accept="image/*,application/pdf"></v-file-input>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <button v-if="User.tpeid != 3 && (PedidosCli.pspId == 1 || PedidosCli.pspId == 2 || PedidosCli.pspId == 8)" type="button" class="btn btn-info" @click.prevent="SalvarDocumento()" style="color:#ffffff;margin-top: 20px;">
                                                            Inserir
                                                        </button>
                                                        <a href="#" v-if="User.tpeid === 3" class="btn btn-info" @click.prevent="EnviarDocumento()" style="color:#ffffff;">
                                                            Enviar
                                                        </a>
                                                    </v-col>
                                                </v-row>
                                                    <v-row>
                                                        <v-table>
                                                            <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                Documento
                                                                </th>
                                                                <th class="text-left">
                                                                Visualização
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody >
                                                            <tr v-for="item in ListDocumentos" :key="item.pmsId">
                                                                <td>{{ item.pmsNome }}</td>
                                                                <td style="text-align: center;">
                                                                    <v-btn v-if="item.pmsUrlImagem != ''" icon @click.prevent="VisualizarDocumento(item)"
                                                                        variant="text">
                                                                        <v-icon>mdi-file-eye</v-icon>
                                                                        <v-tooltip activator="parent"
                                                                            location="top">Visualizar</v-tooltip>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </v-table>
                                                    </v-row>
                                                </v-container>
                                            </v-form>
                                        </v-form>
                                    </v-window-item>
                                </v-window>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--modal Cliente-->
    <v-row justify="center">
        <v-dialog v-model="dialogCliente" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;" >Informações do Cliente</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col v-if="Cliente!=''"  cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row> 
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Nome: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Cliente.cliNomeEmpresa }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF/CNPJ: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliDocumento  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Cliente.tpdId == 2">
                                             <span style="font-weight: 500;">Razão Social: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Cliente.tpdId == 2">
                                             <span>{{ Cliente.cliRazaoSocial  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Cliente.tpdId == 2">
                                             <span style="font-weight: 500;">Responsável: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Cliente.tpdId == 2">
                                             <span>{{ Cliente.cliResponsavel  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Cliente.tpdId == 2">
                                             <span style="font-weight: 500;">CPF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Cliente.tpdId == 2">
                                             <span>{{ Cliente.cliCampoExtra1  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliTelefone }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliWhatsapp }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEmail }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Nascimento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ formattedDate(Cliente.cliDataNascimento) }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Estado Civil: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEstadoCivil }}</span>
                                        </v-col>
                                       
                                    </v-row>
                                </v-col>
                                <v-col v-if="Endereco!=''"  cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <span style="font-weight: 500;">Endereço Alternativo: </span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cleEndereco }} - {{ Endereco.cleNumero }} - {{  Endereco.cleComplemento }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Cidade/UF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cleCidade }} / {{ Endereco.cleEstado }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Bairro: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cleBairro }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CEP: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cleCep }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="Cliente!='' && Cliente!=null && Cliente.cliCpfConjuge &&  Cliente.cliCpfConjuge != '' && Cliente.cliCpfConjuge != null"  cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row> 
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Conjuge: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Cliente.cliNomeConjuge }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliCpfConjuge  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliTelefoneConjuge }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliWhatsappConjuge }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEmailConjuge }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Data Nascimento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ formattedDate(Cliente.cliDataNascimentoConjuge)}}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span v-html="Cliente.cliTexto1"></span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="Cliente!='' && Cliente!=null && Cliente.cliCpfCorresponsavel &&  Cliente.cliCpfCorresponsavel != '' && Cliente.cliCpfCorresponsavel != null"  cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row> 
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Corresponsável: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Cliente.cliNomeCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliCpfCorresponsavel  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliTelefoneCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliWhatsappCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEmailCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Data Nascimento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ formattedDate(Cliente.cliDataNascimentoCorresponsavel)}}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Estado Civil: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEstadoCivilCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Nome Pai: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliNomePaiCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Nome Mãe: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliNomeMaeCorresponsavel }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span v-html="Cliente.cliTexto2"></span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogCliente=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

     <!--modal imobiliaria-->
     <v-row justify="center">
        <v-dialog v-model="dialogImobiliaria" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Informações do Imobiliária</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col v-if="Imobiliaria!=''" cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Nome: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Imobiliaria.imoNomeEmpresa }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF/CNPJ: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.imoDocumento  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3"  v-if="Imobiliaria.tpdId == 2">
                                             <span style="font-weight: 500;">Razão Social: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Imobiliaria.tpdId == 2">
                                             <span>{{ Imobiliaria.imoRazaoSocial  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3"  v-if="Imobiliaria.tpdId == 2">
                                             <span style="font-weight: 500;">Responsável: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Imobiliaria.tpdId == 2">
                                             <span>{{ Imobiliaria.imoResponsavel  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3"  v-if="Imobiliaria.tpdId == 2">
                                             <span style="font-weight: 500;">CPF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Imobiliaria.tpdId == 2">
                                             <span>{{ Imobiliaria.imoCampoExtra1  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.imoTelefone }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.imoWhatsapp }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.imoEmail }}</span>
                                        </v-col>
                                       
                                    </v-row>
                                </v-col>
                                <v-col v-if="EnderecoImob!=''" cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.imeEndereco }} - {{ EnderecoImob.imeNumero }} - {{  EnderecoImob.imeComplemento }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Cidade/UF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.imeCidade }} / {{ EnderecoImob.imeEstado }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Bairro: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.imeBairro }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CEP: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.imeCep }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogImobiliaria=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <!--modal Serasa-->
    <v-row justify="center">
        <v-dialog v-model="dialogSerasa" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Análise de Crédito</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px" v-if="ListaSerasa != '' && ListaSerasa != null">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col  cols="12" md="12" style="border-right: 1px solid #cccccc;">
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PF_PME'">
                                        <v-col cols="12" md="12">
                                           Dados Cadastrais
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Relatório: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].reportName }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Score: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.score }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Probabilidade de Inadimplência: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.defaultRate }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Situação Documento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].registration.statusRegistration }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cliente:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.consumerName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Nome da mãe: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.motherName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Data Nascimento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ formattedDate(ListaSerasa.reports[0].registration.birthDate)  }}</span>
                                        </v-col>                                      
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PJ_PME'">
                                        <v-col cols="12" md="12">
                                           Dados Cadastrais
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Relatório: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].reportName }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Score: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.score }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Probabilidade de Inadimplência: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ (ListaSerasa.reports[0].score.defaultRate/100) }} %</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Situação Documento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].registration.statusRegistration }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Documento:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.companyDocument  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cliente:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.companyName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Fundação: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  formattedDate(ListaSerasa.reports[0].registration.foundationDate)  }}</span>
                                        </v-col>      
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cidade: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.address.city  }}</span>
                                        </v-col>    
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Estado: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.address.state  }}</span>
                                        </v-col>                             
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.pefin && 
                                                 ListaSerasa.reports[0].negativeData.pefin.pefinResponse && 
                                                 ListaSerasa.reports[0].negativeData.pefin.pefinResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Pendências Financeiras (PEFIN)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(pefin,index) in ListaSerasa.reports[0].negativeData.pefin.pefinResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(pefin.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(pefin.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Pendências Financeiras (PEFIN)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.refin != '' && 
                                                 ListaSerasa.reports[0].negativeData.refin.refinResponse &&
                                                 ListaSerasa.reports[0].negativeData.refin.refinResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Restrições Financeiras (REFIN)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(refin,index) in ListaSerasa.reports[0].negativeData.refin.refinResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(refin.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(refin.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Restrições Financeiras (REFIN)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.notary &&
                                                 ListaSerasa.reports[0].negativeData.notary.notaryResponse &&
                                                 ListaSerasa.reports[0].negativeData.notary.notaryResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas – Protestos
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(prot,index) in ListaSerasa.reports[0].negativeData.notary.notaryResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(prot.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(prot.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Número do Cartório: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.officeNumber }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cartório: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.officeName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas – Protestos
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                ListaSerasa.reports[0].negativeData.check && 
                                                ListaSerasa.reports[0].negativeData.check.checkResponse &&
                                                ListaSerasa.reports[0].negativeData.check.checkResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas - Cheques Sem Fundo (CCF)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(cheq,index) in ListaSerasa.reports[0].negativeData.check.checkResponse" :key="index"  :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(cheq.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do banco: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do banco: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Número da agência: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankAgencyId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Quantidade de cheques: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.checkCount }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas - Cheques Sem Fundo (CCF)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.collectionRecords && 
                                                 ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse &&
                                                 ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas - Dívidas Vencidas (Convem)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(convem,index) in ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(convem.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(convem.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas - Dívidas Vencidas (Convem)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PJ_PME' && 
                                                 ListaSerasa.reports[0].director && 
                                                 ListaSerasa.reports[0].director.DirectorResponse &&
                                                 ListaSerasa.reports[0].director.DirectorResponse.results.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Administradores
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(dire,index) in ListaSerasa.reports[0].director.DirectorResponse.results" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Tipo Documento: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.documentType }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Documento: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.documentId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.name }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cargo: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.role }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Possui restrição: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.hasNegative ? "Sim" : "Não" }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogSerasa=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

      <!--modal JusBrasil-->
      <v-row justify="center">
        <v-dialog v-model="dialogJusBrasil" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Informações Jurídicas</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px" v-if="ListaJusBrasil != '' && ListaJusBrasil != null">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col  cols="12" md="12" style="border-right: 1px solid #cccccc;" v-if="ListaJusBrasil.processos && 
                                                 ListaJusBrasil.processos.length > 0">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                           Dados Cadastrais
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Nome: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaJusBrasil.nome }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Tipo: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaJusBrasil.identificacao.tipo }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Documento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaJusBrasil.identificacao.valor }}</span>
                                        </v-col>                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Processos
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(proc,index) in ListaJusBrasil.processos" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Tipo Processo: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.tipo_processo }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data Atualização: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.data_ultima_atualizacao }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.natureza }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Assunto: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.assunto }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Ano Processo: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.ano_do_processo }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Tribunal: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.tribunal }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">UF: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.UF }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Comarca: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.comarca }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Fórum: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.forum }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor da causa: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(proc.valor_causa) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Numero Processo: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.numero_processo }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Link: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.link }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Status: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ proc.status }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-else>
                                    <v-col v-if="ListaJusBrasil.fault" cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                        {{ ListaJusBrasil.fault.faultstring }}
                                    </v-col>
                                    <v-col v-else cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                        Nada Consta
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogJusBrasil=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';
import VueSpeedometer from "vue-speedometer";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


export default {
    name: 'ContratacaoVigenteDetalheView',
    components: {
        EasyDataTable,
        moment,
        VueSpeedometer
    },
    setup() {
        const route = useRoute();
        const id = route.params.idPed;

        return {
            id,
        };
    },
    data: () => ({
        User:{},
        tab: null,
        NomeTela: 'Contratações',
        InfoTela: 'Detalhe Contratação',
        MostraGrid: true,
        Cliente: [],
        Imobiliaria: [],
        PlanosGarantias:[],
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 28, cdtNome: "Detalhe Contratação" }],
        PedidosCli: [],
        Pedidos: [],
        TotalPedido: 0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova: false,
        ListaPedidoItem: [],
        ListaPedidoItemAdicional: [],
        PedidosMensagem: [],
        PedidosAgendamento: [],
        PedidosEndereco: [],
        PedidosComprova:[],  
        Estados: [],
        Cidades: [],
        Bairros: [],
        selectedFile: '',
        urlComprovante: '',
        PedidoComprovante: [],
        Situacoes: [],
        dialogCliente:false,
        dialogImobiliaria:false,
        ListEndereco:[],
        EnderecoImob:{},
        ListEnderecoImob:[],
        Endereco:{},
        ValidaCustomer:{},
        DadosGarantidora:[],
         //Documentos
         ListDocumentos:[],
        Documentos: {
            cdoId:0,
            cdoDocumento:'', 
            cdoNomeDocumento:'', 
            cdoUrlDocumento:'',
            cliId:'',
        },
        DocSelected:null,
        ListaDocs:[],
        TiposDocumentos:[],
        ListEntidadesDocumentos:[],
        selectedFile: '',
        //serasa
        score: 0,
        dialogSerasa:false,
        ListaSerasa: null,
        ListaJusBrasil: null,
        ListaInvoice: null,
        ListaProdutosImg:[],
        dialogJusBrasil: false,
        nomeDocumento:'',
        ContratoPedido:[],
        Assinatura:[],
        ListaContratos:[],
        ListDocumentosAssinado:[],
        ListDocumentosAss:null,
    }),
    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            // Verifica se o valor é inteiro
            if (Number.isInteger(valor)) {
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            // Formata normalmente para valores com casas decimais
            return parseFloat(valor).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        calculatotal() {
            var valorProdutos = 0;

            if (this.ListaPedidoItem != '') {
                this.ListaPedidoItem.forEach(item => {
                    valorProdutos += item.pdiValorTotalProduto;
                });
            }

            this.TotalPedido = (valorProdutos);
        },
        async PutSituacao(value) {
            //alterar data vigência
            let _pedidosItems = this.ListaPedidoItem.map(produto => ({
                ...produto,
                pdiDataVigenciaIni: moment(produto.pdiDataVigenciaIni).format("YYYY-MM-DD"),
                pdiDataVigenciaFim: moment(produto.pdiDataVigenciaFim).format("YYYY-MM-DD")
            }));

            _pedidosItems.forEach((itemprod) => {
                   axios.put(`/Process/PedPedidoItem/Update`, itemprod)
             });         
            
            //validar se foi solicitado documentos
            let _validaSolicDocs = await this.ListDocumentos.filter(x=> x.pmsNome != '' && x.pmsNome != null && (x.pmsUrlImagem == '' || x.pmsUrlImagem == null)).length;
            
            //seguir avisos e proxima etapa
            if (value == 8){ 
                   this.PedidosCli.pspId = value;
                   this.PedidosCli.pedSituacaoPedido = 'Aguardando Documentos';
                    await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                    this.$mensagemAviso("Dados Salvos, foi enviado aviso via e-mail para imobiliária.")
                    var _texto = "Solicitamos documentação complementar para análise da contratação. ";
                    this.EnviaAvisoImobiliariaDocs(_texto);
                    this.$router.push('/listacontratacoes');
            }
            else if (value == 4){ 
                this.PedidosCli.pspId = value;
                this.PedidosCli.pedSituacaoPedido = 'Não Aprovado';
                await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                this.$mensagemAviso("Dados Salvos, foi enviado aviso via e-mail para imobiliária.")
                var _texto = "Contratação não foi aprovada. ";
                this.EnviaAvisoImobiliariaStatus(_texto);
                this.$router.push('/listacontratacoes');
            }
            else if (value == 6){ 
                if ((this.PedidosCli.pedObsCliente == '' || this.PedidosCli.pedObsCliente == null) && (this.User.tpeid === 3)){
                    this.$mensagemAviso("Digite uma observação sobre o motivo do cancelamento.")
                }
                else if ((this.PedidosCli.pedObsLoja == '' || this.PedidosCli.pedObsLoja == null) && (this.User.tpeid != 3)){
                    this.$mensagemAviso("Digite uma observação sobre o motivo do cancelamento.")
                }
                else{
                    this.PedidosCli.pspId = value;
                    this.PedidosCli.pedSituacaoPedido = 'Cancelado';
                    await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                    this.$mensagemAviso("Contratação Cancelada.")
                    this.$router.push('/contratacoesnegadocancelado');
                }
            }
            else if (value == 2){ 
                if (_validaSolicDocs == 0){
                    this.PedidosCli.pspId = value;
                    this.PedidosCli.pedSituacaoPedido = 'Análise Manual';
                    await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                    this.$mensagemAviso("Contratação enviada para análise.")
                    var _texto = "Contratação reenviada para Análise Manual.";
                    this.EnviaAvisoGarantidoraStatus(_texto);
                    this.$router.push('/listacontratacoes');
                }
                else{
                    this.$mensagemAviso("Existem documentos solicitados não enviados.")
                }
            }
            else if (value == 5){
                if (_validaSolicDocs == 0){
                    this.PedidosCli.pspId = value;
                    this.PedidosCli.pedSituacaoPedido = 'Aprovado Gerar Cobrança';
                    await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                   
                    //enviar para assinatura
                    var _resultAssinatura = await this.EnviarAssinatura(this.PedidosCli);

                    if (_resultAssinatura == 1){
                        this.PedidosCli.pspId = 9;
                        this.PedidosCli.pedSituacaoPedido = 'Aguardando Assinatura';
                        await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                        this.$mensagemAviso("Dados Salvos, foi enviado link de assinatura contrato entre Garantidora e Cliente.")
                        this.$router.push('/listacontratacoes');
                    }
                    else{
                        this.$mensagemAviso("Não foi possível enviar contrato para assinaturas.")
                    }
                   
                }
                else{
                    this.$mensagemAviso("Existem documentos solicitados não enviados.")
                }
            }
        },
        async EnviarAssinatura(pedi) {
            try {
                const responseGarantidora = await axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + this.DadosGarantidora.garId);

                if (responseGarantidora.data.length > 0) {
                    this.Assinatura = responseGarantidora.data[0];

                    const responseDocumentos = await axios.get(`/Process/PedPedidoDocumento/GetAllByPedId/` + pedi.pedId);

                    if (responseDocumentos.data.length > 0) {
                        this.ContratoPedido = responseDocumentos.data;

                        // Executar as chamadas de criação de envelope de forma sequencial ou paralela
                        for (const element of this.ContratoPedido) {
                            const idContrato = element.pftId;
                            const tpeId = 4;
                            const DadosEnvelopeRequest = {
                                token: this.Assinatura.gasToken,
                                envelopeDescricao: this.ListaContratos.filter(x => x.cmcId == 4)[0].cmcNome,
                                repositorioId: parseInt(this.Assinatura.gasIdRepositorio),
                                listaDocumentos: [{ UrlArquivo: element.pftUrlImagem }],
                                listaSignatariosEnvelopes: [
                                    {
                                        Ordem: 1,
                                        EmailSignatario: this.Cliente.cliEmail,
                                        NomeSignatario: this.Cliente.cliNomeEmpresa,
                                        TipoAssinatura: 1,
                                    },
                                    {
                                        Ordem: 2,
                                        EmailSignatario: this.DadosGarantidora.garEmail,
                                        NomeSignatario: this.DadosGarantidora.garNomeEmpresa,
                                        TipoAssinatura: 1,
                                    },
                                ],
                            };

                            try {
                                const responseEnvelope = await axios.post(
                                    `/Process/AssinaturasAsten/CreateEnvelope/` + idContrato + '/' + tpeId,
                                    DadosEnvelopeRequest
                                );

                                if (responseEnvelope.status === 200) {
                                    console.log("Envelope criado com sucesso:", responseEnvelope.data);
                                } else {
                                    console.error("Erro ao criar envelope:", responseEnvelope.status, responseEnvelope.data);
                                    return 0;
                                }
                            } catch (error) {
                                console.error("Erro ao enviar assinatura:", error.message);
                                return 0;
                            }
                        }

                        return 1; // Sucesso
                    } else {
                        console.error("Nenhum documento encontrado para o pedido.");
                        return 0; // Falha
                    }
                } else {
                    console.error("Nenhuma garantidora encontrada para o ID fornecido.");
                    return 0; // Falha
                }
            } catch (error) {
                console.error("Erro geral na função EnviarAssinatura:", error.message);
                return 0; // Falha
            }
        },
        EnviaAvisoImobiliariaDocs(texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 11);
                            let _dadosEmail = {
                                            nomeDe: this.DadosGarantidora.garNomeEmpresa,
                                            nomePara: this.Imobiliaria.imoNomeEmpresa,
                                            assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                                            destinatario: this.Imobiliaria.imoEmail,
                                            emailResposta: this.DadosGarantidora.garEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeImobiliaria]',this.Imobiliaria.imoNomeEmpresa)
                                                        .replace('[ContratacaoId]',this.PedidosCli.pedId)
                                                        .replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                        .replace('[Texto]', texto)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        EnviaAvisoImobiliariaStatus(texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 12);
                            let _dadosEmail = {
                                            nomeDe: this.DadosGarantidora.garNomeEmpresa,
                                            nomePara: this.Imobiliaria.imoNomeEmpresa,
                                            assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                                            destinatario: this.Imobiliaria.imoEmail,
                                            emailResposta: this.DadosGarantidora.garEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomePara]',this.Imobiliaria.imoNomeEmpresa)
                                                        .replace('[ContratacaoId]',this.PedidosCli.pedId)
                                                        .replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                        .replace('[Texto]', texto)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        EnviaAvisoGarantidoraStatus(texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 12);
                            let _dadosEmail = {
                                            nomeDe: this.Imobiliaria.imoNomeEmpresa,
                                            nomePara: this.DadosGarantidora.garNomeEmpresa,
                                            assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                                            destinatario: this.DadosGarantidora.garEmail,
                                            emailResposta: this.Imobiliaria.imoEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomePara]',this.DadosGarantidora.garNomeEmpresa)
                                                        .replace('[ContratacaoId]',this.PedidosCli.pedId)
                                                        .replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                        .replace('[Texto]', texto)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        async openDialogCliente(value){
         //carrega endereco
         await axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ value)
                        .then(response => {
                            this.ListEndereco = response.data;                             
                            if (this.ListEndereco != ''){
                                this.Endereco = this.ListEndereco[0];
                                this.dialogCliente = true;
                                //console.log('this.Endereco')
                                //console.log(this.Endereco)
                            }            
                        });
        },
        async openDialogImobiliaria(value){
         //carrega endereco
         await axios.get(`/Process/ImoImobiliariaEndereco/GetByImoId/`+ value)
                        .then(response => {
                            this.ListEnderecoImob = response.data;                             
                            if (this.ListEnderecoImob != ''){
                                this.EnderecoImob = this.ListEnderecoImob[0];
                                this.dialogImobiliaria = true;
                                //console.log('this.EnderecoImob')
                                //console.log(this.EnderecoImob)
                            }            
                        });
        },
        async SalvarDocumento(){
            if (this.nomeDocumento == '' || this.nomeDocumento == null) {
                this.$mensagemAviso("Digite um Documento a ser solicitado.")
            }
            else{
                  var _docNome =  this.nomeDocumento;
                        let _pedidoDocumentoAnalise = {
                        PmsId: 0,
                        GrtId: 0,
                        CdtId: 0,
                        PmsUrlImagem: "",
                        PmsNome: _docNome,
                        PmsTipo: "",
                        PmsTexto: "", 
                        PmsCampoExtra1: "",
                        PedId: this.PedidosCli.pedId,
                         };

                        await axios.post(`/Process/PedPedidoDocumentoAnalise/Add`, _pedidoDocumentoAnalise)
                            .then((response) => {
                            if (response.data > 0) {
                                axios.get('/Process/PedPedidoDocumentoAnalise/GetAllByPedId/' + this.PedidosCli.pedId)
                                    .then(response => {
                                        this.ListDocumentos = response.data;
                                        this.nomeDocumento = '';
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }
                            });
            }
        },
        async EnviarDocumento(){
            if (this.DocSelected == '' || this.DocSelected == null) {
                this.$mensagemAviso("Selecione o Tipo de Documento a ser enviado.")
            }
            else if (this.selectedFile == '' || this.selectedFile == null){
                this.$mensagemAviso("Selecione um Documento a ser enviado.")
            }
            else{
                if (this.selectedFile != '' && this.selectedFile != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        let _pedidoMensagem = {
                            PmsId: 0,
                            GrtId: 0,
                            CdtId: 0,
                            PmsUrlImagem: uploadSuccess.data.nameFile,
                            PmsNome: this.DocSelected.pmsNome,
                            PmsTipo: "",
                            PmsTexto: "", 
                            PmsCampoExtra1: "",
                            PedId: this.PedidosCli.pedId,
                         };
 
                         await axios.post(`/Process/PedPedidoDocumentoAnalise/Add`, _pedidoMensagem)
                            .then((response) => {
                            if (response.data > 0) {
                                axios.get('/Process/PedPedidoDocumentoAnalise/GetAllByPedId/' + this.PedidosCli.pedId)
                                    .then(response => {
                                        this.ListDocumentos = response.data;
                                        let _pmsdel = this.ListDocumentos.filter(x=> x.pmsNome == this.DocSelected.pmsNome && (x.pmsUrlImagem == '' || x.pmsUrlImagem == null));
                                        if (_pmsdel != ''){
                                            let _pmsId = _pmsdel[0].pmsId
                                                axios.delete(`/Process/PedPedidoDocumentoAnalise/Delete/${_pmsId}`)
                                                        .then(response => {
                                                            if (response.status == 200) {
                                                                //carrega documentos
                                                                axios.get('/Process/PedPedidoDocumentoAnalise/GetAllByPedId/' + this.PedidosCli.pedId)
                                                                .then(response => {
                                                                    this.ListDocumentos = response.data;
                                                                    this.DocSelected = null;
                                                                    this.selectedFile = '';
                                                                })
                                                                .catch(error => {
                                                                    console.error(error);
                                                                });
                                                            }
                                                        });
                                        }
                                        else{
                                            this.DocSelected = null;
                                            this.selectedFile = '';
                                        }
                                       
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
              }
         }
        },
        DeleteDocumento(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pmsId;
                axios.delete(`/Process/PedPedidoDocumentoAnalise/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                         //carrega documentos
                         axios.get('/Process/PedPedidoDocumentoAnalise/GetAllByPedId/' + this.PedidosCli.pedId)
                        .then(response => {
                            this.ListDocumentos = response.data;
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    }
                });
            }
            });
        },
        VisualizarDocumento(_contrato){
            if (_contrato && _contrato.pmsUrlImagem) {
            // Abre o PDF em uma nova aba
            window.open(this.$imgURL + _contrato.pmsUrlImagem, '_blank');
        } else {
            console.error("URL não está disponível.");
        }
        },
        AcionarSinistro(){

        },
        async Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }

            axios.get(`/Process/PedPedidos/GetById/` + this.id)
                .then(response => {
                    this.PedidosCli = response.data;
                    // console.log('this.PedidosCli')
                    // console.log(this.PedidosCli)
                    if (this.PedidosCli.pedSerasa != '' && this.PedidosCli.pedSerasa != null){
                    this.score = this.PedidosCli.pedScore;
                    this.ListaSerasa = JSON.parse(this.PedidosCli.pedSerasa);
                    }

                    if (this.PedidosCli.pedJusBrasil != '' && this.PedidosCli.pedJusBrasil != null){
                        const cleanedJson = this.PedidosCli.pedJusBrasil
                            .replace(/,\s*}/g, '}') // Remove vírgula antes de fechamentos de objetos
                            .replace(/,\s*]/g, ']'); // Remove vírgula antes de fechamentos de arrays

                        // Faz o parse do JSON corrigido
                        this.ListaJusBrasil = JSON.parse(cleanedJson);
                    }

                    if (this.PedidosCli.pedInvoiceId != '' && this.PedidosCli.pedInvoiceId != null){
                        this.ListaInvoice = JSON.parse(this.PedidosCli.pedInvoiceId);
                        //  console.log('this.Cliente')
                        //  console.log(this.Cliente)
                    }


                axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.cliId)
                    .then(response => {
                        this.Cliente = response.data;
                        //  console.log('this.Cliente')
                        //  console.log(this.Cliente)
                    });

                    axios.get(`/Process/ImoImobiliarias/GetById/` + this.PedidosCli.imobId)
                        .then(response => {
                            this.Imobiliaria = response.data;
                            //  console.log('this.Imobiliaria')
                            //  console.log(this.Imobiliaria)
                     });

                     axios.get(`/Process/GarGarantidoras/GetById/` + this.PedidosCli.garId)
                        .then(response => {
                            this.DadosGarantidora = response.data;
                            //  console.log('this.Imobiliaria')
                            //  console.log(this.Imobiliaria)
                     });

                     axios.get('/Process/PedPedidoDocumentoAnalise/GetAllByPedId/' + this.PedidosCli.pedId)
                        .then(response => {
                            this.ListDocumentos = response.data;
                        })
                        .catch(error => {
                            console.error(error);
                        });

                        axios.get(`/Process/CadModelosContrato/GetAll`)
                        .then(response => {
                            this.ListaContratos = response.data.filter(x=> x.tpeId == 1 && x.cmcAtivo);
                        });

                        axios.get('/Process/PedPedidoDocumento/GetAllByPedId/' + this.PedidosCli.pedId)
                        .then(response => {
                            this.ListDocumentosAss = response.data;
                           /* if (this.ListDocumentosAss != '' && this.ListDocumentosAss != null){
                                this.ListDocumentosAssinado = JSON.parse(this.ListDocumentosAss[0]);
                                //  console.log('this.Cliente')
                                //  console.log(this.Cliente)
                            }*/
                        })
                        .catch(error => {
                            console.error(error);
                        });
                   

                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;

                    this.ListaPedidoItem = this.ListaPedidoItem.map(produto => ({
                        ...produto,
                        pdiDataVigenciaIni: produto.pdiDataVigenciaIni.split('T')[0],
                        pdiDataVigenciaFim: produto.pdiDataVigenciaFim.split('T')[0]
                    }));

                   // console.log('ListaPedidoItem')
                   // console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                   // console.log('ListaPedidoItemAdicional')
                   // console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });


            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                   // console.log('PedidosEndereco')
                   // console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosComprova = response.data;
                   // console.log('PedidosComprova')
                   // console.log(this.PedidosComprova)
                })
                .catch(error => {
                    console.error(error);
                });


            axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data; //.filter(x=> x.pspId != 1 &&  x.pspId != 3 &&  x.pspId != 5 &&  x.pspId != 6);
                   // console.log('this.Situacoes')
                   // console.log(this.Situacoes)
                });

                axios.get(`/Process/ProProdutosImagens/GetAll`)
                        .then(response => {
                            this.ListaProdutosImg = response.data;
                              console.log('this.ListaProdutosImg')
                              console.log(this.ListaProdutosImg)
                     });

         

            this.calculatotal();
        }
    },
    created() {
        this.Get();
    },
    computed: {
    uniqueDocumentos() {
      // Cria uma lista única com base em `pmsNome`
      const seen = new Set();
      return this.ListDocumentos.filter(option => {
        const isDuplicate = seen.has(option.pmsNome);
        seen.add(option.pmsNome);
        return !isDuplicate;
      });
    },
  },
}
</script>

<style scoped>
.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    /*border: 1px solid #333333;*/
    padding: 3px;
}

@media (min-width:1200px) {
    .controlcelEnd {
    height: 600px;
        width: calc(100% - 248px) !important;
        display: flex;
        flex-direction: column;
    }

}

.bg-custom-white {
    background-color: white;
}

.bg-custom-light-blue {
    background-color: #c5dce7; /* Customize conforme necessário */
}

</style>
